import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import { TextField, Button, Typography, Alert, Link } from "@mui/material";
import Layout from "../layout/Layout";
import { getFirebaseErrorMessage } from "../../firebase/firebaseErrors";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Redirect to dashboard or home page
    } catch (err) {
      console.error("Firebase Auth Error:", err); // Log the err object
      setError(getFirebaseErrorMessage(err.code));
    }
    navigate("/dashboard");
  };

  return (
    <Layout>
      <Typography component="h1" variant="h5">
        Login
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <form onSubmit={handleSubmit}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Login
        </Button>
        <Typography align="center">
          <Link href="/register" variant="body2">
            {"Don't have an account? Register"}
          </Link>
        </Typography>
        <Typography align="center">
          <Link href="/forgot-password" variant="body2">
            Forgot password?
          </Link>
        </Typography>
      </form>
    </Layout>
  );
}

export default Login;
