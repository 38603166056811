import OpenAI from "openai";
import { fetchTodayData } from "../utils/todayData";
import { lastUpdatedPlus } from "../utils/timerUtils";
import { getUserInfo } from "../utils/userData";
import { auth } from "../../../firebase/firebase";
import { getMessages, addMessage } from "./messages";
import openaiConfig from "./openaiConfig";

const openai = new OpenAI(openaiConfig);

const text2Speech = async (assistantVoice, message) => {
  try {
    const response = await openai.audio.speech.create({
      model: "tts-1",
      voice: assistantVoice,
      input: message,
    });

    // Convert the response to a Blob
    const blob = new Blob([await response.arrayBuffer()], {
      type: "audio/mp3",
    });

    // Create an audio element and set the source to the Blob URL
    const audio = new Audio(URL.createObjectURL(blob));

    // Play the audio
    audio.play();
  } catch (error) {
    console.error("Error generating speech: ", error);
  }
};

export const sendMessageToChatGPT = async (
  agent,
  otherAgents,
  userMessage = "",
  data
) => {
  const todayData = await fetchTodayData(auth.currentUser.uid);
  const userInfo = await getUserInfo(auth.currentUser.uid);
  const messages = await getMessages(auth.currentUser.uid);

  console.log({ data });

  await lastUpdatedPlus(agent.task, 10);

  let systemMessage = `
You are a highly specialized ${agent.title}.

${agent.instructions}

You are a highly specialized ${agent.name}.

### Your Mission
${agent.instructions}

### Key Data
- **Personal Activities & Points**: I track my activities and the points I've earned for each in the following JSON object:
${JSON.stringify(data)}

### Output Structure
When generating a response, adhere strictly to the following JSON schema for your output:
{
  "message": "Your response should be laser-focused on my current activities or habits. Provide specific, personalized guidance that directly aligns with my progress, goals, and recorded activities. Incorporate brief but powerful motivational quotes where applicable. If additional details are needed to fine-tune your advice, ask specific, targeted questions.",
  "activity_improvements": {
    "add": [Array of objects. Each object represents a new activity to add, structured as {"title": "Title of the activity", "parent": "Title of the parent activity"}. Only suggest additions if they are crucial for enhancing my routine or addressing gaps in my activities. Clearly explain how the new activity complements my existing routine.],
    "modify": [Array of objects. Each object represents an activity to modify, structured as {"old_title": "Current title of the activity", "new_title": "New title of the activity", "old_parent": "Title of the current parent activity", "new_parent": "Title of the new parent activity"}. Propose modifications only if they significantly improve alignment with my goals, better reflect my progress, or optimize my routine for efficiency or effectiveness.],
    "delete": [Array of objects. Each object represents an activity to delete, structured as {"title": "Title of the activity", "parent": "Title of the parent activity"}. Recommend deletions only if an activity is redundant, counterproductive, or no longer relevant. Provide a clear rationale for how this deletion benefits my overall journey.]
  },
  "suggested_accomplishments": "If a recent accomplishment I describe doesn't clearly align with the activities in my JSON object, provide an array of suggested activities, structured as {"title": "Title of the activity", "parent": "Parent of the activity"}. If no clarification is needed, return an empty array [].",
  "improved_instructions": "If you find it necessary to improve your own instructions, use this field to generate a new version of your instructions. Note that you can only improve your instructions, not other parts of the prompt."
}

### Additional Personal Context
${
  userInfo?.firstname
    ? `- **First Name**: My first name is ${userInfo.firstname}.`
    : ""
}
${userInfo?.age ? `- **Age**: I am ${userInfo.age} years old.` : ""}
- **Date and Time**: The current date and time are: ${new Date().toString()}.
- **Today’s Accomplishments**: So far today, I have accomplished: \n${JSON.stringify(
    todayData
  )}

### Guidance Protocols
- **Practical & Achievable**: Ensure all advice is realistic and actionable, based on my recorded activities and stated goals. Avoid generic advice; specificity is essential.
- **Progressive Development**: Where relevant, suggest gradual progression in difficulty or intensity to foster continuous improvement. Outline a clear, step-by-step path for progression.
- **Personalization**: Identify any repeated patterns or habits in my activities. Leverage this data to provide tailored advice that aligns with my preferences and strengths.
- **Routine Diversification**: If my activity data suggests monotony, propose specific, varied activities to prevent plateaus and maintain engagement. Clearly explain the benefits of each proposed change.
- **Safety & Injury Prevention**: If any injuries or limitations are mentioned, prioritize safety. Recommend modifications or alternative exercises that avoid strain or injury aggravation while still promoting progress.

### Core Principles
Your guidance is instrumental to my long-term success. Every suggestion you provide has the potential to influence my decisions and actions, potentially leading to significant long-term rewards. Therefore, every piece of advice must be delivered with precision, relevance, and motivational impact.`;

  const systemMessageObj = {
    role: "system",
    content: systemMessage,
  };

  const userMessageObjs = [];

  for (let messgge of messages) {
    userMessageObjs.push({
      role: messgge.role,
      content: messgge.content,
    });
  }
  if (userMessage) {
    userMessageObjs.push({
      role: "user",
      content: userMessage,
    });
    await addMessage(agent, auth.currentUser.uid, "user", userMessage);
  }

  const messagePayload = {
    model: "gpt-4o",
    messages: [systemMessageObj, ...userMessageObjs],
    response_format: { type: "json_object" },
    temperature: 0,
  };

  let responseObj = false;
  while (
    !responseObj ||
    !responseObj.hasOwnProperty("message") ||
    responseObj.message === ""
  ) {
    const response = await openai.chat.completions.create(messagePayload);

    try {
      responseObj = JSON.parse(response.choices[0].message.content);
    } catch (err) {
      console.error(err.message);
    }
  }
  let responseMessage = responseObj.message;
  await text2Speech(agent.voice, responseMessage);

  // const improvements = responseObj.activity_improvements;
  // if (improvements?.additions?.length > 0) {
  //   for (let addition of improvements.additions) {
  //     if (addition.title && addition.parent) {
  //       responseMessage +=
  //         "\n\nI suggest you add a new activity under " +
  //         addition.parent +
  //         " with the title " +
  //         addition.title +
  //         "\n";
  //     }
  //   }
  // }
  // if (improvements?.modifications?.length > 0) {
  //   for (let modification of improvements.modifications) {
  //     if (modification.old_title && modification.old_parent) {
  //       responseMessage +=
  //         "\n\nI suggest you modify the activity under " +
  //         modification.old_parent +
  //         " as follows:\n";
  //       if (modification.new_parent) {
  //         responseMessage +=
  //           "Change its parent from " +
  //           modification.old_parent +
  //           " to " +
  //           modification.new_parent +
  //           "\n";
  //       }
  //       if (modification.new_title) {
  //         responseMessage +=
  //           "Change its title from " +
  //           modification.old_title +
  //           " to " +
  //           modification.new_title +
  //           "\n";
  //       }
  //     }
  //   }
  // }
  // if (improvements?.deletions?.length > 0) {
  //   for (let deletion of improvements.deletions) {
  //     if (deletion.title && deletion.parent) {
  //       responseMessage +=
  //         "\n\nI suggest you delete the activity under " +
  //         deletion.parent +
  //         " with the title " +
  //         deletion.title +
  //         "\n";
  //     }
  //   }
  // }
  // const suggestedAccomplishments = responseObj.suggested_accomplishments;
  // if (suggestedAccomplishments?.length > 0) {
  //   for (let suggestedAccomplishment of suggestedAccomplishments) {
  //     if (suggestedAccomplishment.title && suggestedAccomplishment.parent) {
  //       responseMessage +=
  //         "\n\nI suggest you give yourself a point for the activity under " +
  //         suggestedAccomplishment.parent +
  //         " with the title " +
  //         suggestedAccomplishment.title +
  //         "\n";
  //     }
  //   }
  // }

  // Save the assistant's message
  await addMessage(agent, auth.currentUser.uid, "assistant", responseMessage);
  // return { message: responseMessage, suggestions: suggestedAccomplishments };
  return responseMessage;
};
