import { db, auth } from "../../../firebase/firebase";
import {
  doc,
  query,
  collection,
  where,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";

export const fetchLastResetTime = async (uid) => {
  const timerDocRef = doc(db, "timestamps", uid);
  const docSnap = await getDoc(timerDocRef);
  return docSnap.exists() ? docSnap.data().lastResetTime.toDate() : null;
};

export const updateLastResetTime = async (uid) => {
  const timerDocRef = doc(db, "timestamps", uid);
  await setDoc(timerDocRef, { lastResetTime: new Date() });
};

export const setLastUpdated = async (agentTitle) => {
  const agentNodeQuery = query(
    collection(db, "progress"),
    where("title", "==", agentTitle),
    where("uid", "==", auth.currentUser.uid)
  );
  const querySnapshot = await getDocs(agentNodeQuery);
  if (querySnapshot.docs.length > 0) {
    const agentDocRef = doc(db, "progress", querySnapshot.docs[0].id);
    await updateDoc(agentDocRef, { lastResetTime: new Date() });
  }
};

export const lastUpdatedPlus = async (agentTitle, spacing) => {
  const agentNodeQuery = query(
    collection(db, "progress"),
    where("title", "==", agentTitle),
    where("uid", "==", auth.currentUser.uid)
  );
  const querySnapshot = await getDocs(agentNodeQuery);
  if (querySnapshot.docs.length > 0) {
    const agentDocRef = doc(db, "progress", querySnapshot.docs[0].id);
    const agentData = querySnapshot.docs[0].data();
    await updateDoc(agentDocRef, {
      lastResetTime: new Date(new Date().getTime() + spacing * 60000),
    });
  }
};

// Convert duration (minutes) to Date object
export const durationToDate = (durationMinutes) => {
  const date = new Date(0);
  const hours = Math.floor(durationMinutes / 60);
  const minutes = durationMinutes % 60;
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

// Convert Date object to duration (minutes)
export const dateToDuration = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return hours * 60 + minutes;
};
