import React, { useState, useEffect, useRef } from "react";
import { TreeView, TreeItem } from "@mui/x-tree-view";
import { Alert, Button, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import TextField from "@mui/material/TextField";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import {
  collection,
  query,
  where,
  onSnapshot,
  updateDoc,
  doc,
  getDocs,
  addDoc,
  deleteDoc,
  increment,
  writeBatch,
} from "firebase/firestore";
import { db, auth } from "../../firebase/firebase";

import {
  getTodayString,
  fetchTodayData,
  clearTodayData,
} from "./utils/todayData";
import {
  fetchLastResetTime,
  updateLastResetTime,
  setLastUpdated,
  durationToDate,
  dateToDuration,
} from "./utils/timerUtils";
import { buildTree } from "./utils/buildTree";
import { getUserInfo } from "./utils/userData";
import { updateActivity } from "./api/activity";
import { sendMessageToChatGPT } from "./api/openai";
import { DeleteDialog } from "./dialogs/DeleteDialog";
import { DecrementDialog } from "./dialogs/DecrementDialog";
import { ChatBox } from "./ChatBox";

// Add a constant for the height of your navbar
const navbarHeight = "70px";
const durationToAlert = 600; // = 10 minutes
let talking = false;

// The Component
const Dashboard = () => {
  const inputRef = useRef(null);
  const dividerPositionRef = useRef();

  // const [leftPanelWidth, setLeftPanelWidth] = useState(50); // Percentage of the left panel width
  const [UID, setUID] = useState(null);
  const [progressData, setProgressData] = useState([]);
  const [maxPoints, setMaxPoints] = useState(0);
  const [editingNode, setEditingNode] = useState(null);
  const [newNodeId, setNewNodeId] = useState(null);
  const [timer, setTimer] = useState(durationToAlert);
  const [showAlert, setShowAlert] = useState(false);
  const [stopd, setStop] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [nodeToDecrement, setNodeToDecrement] = useState(null);
  const [nodeToDelete, setNodeToDelete] = useState(null);
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);
  const [agents, setAgents] = useState([]);
  const [horizontal, setHorizontal] = useState(true);
  const [expandedItems, setExpandedItems] = React.useState([]);

  useEffect(() => {
    const handleResize = () => {
      setHorizontal(window.innerWidth > window.innerHeight ? true : false);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call the function initially

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const userID = auth.currentUser.uid;
    setUID(userID);

    // const checkAndUpdateTimer = async () => {
    //   const lastResetTime = await fetchLastResetTime(userID);
    //   if (
    //     !lastResetTime ||
    //     new Date().getTime() - new Date(lastResetTime).getTime() >
    //       durationToAlert * 1000
    //   ) {
    //     await updateLastResetTime(userID);
    //   }
    // };

    // checkAndUpdateTimer();

    const timestampsUnsubscribe = onSnapshot(
      doc(db, "timestamps", userID),
      async (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          const lastResetTime = new Date(data.lastResetTime.toDate()).getTime();
          const timeDiff = Math.floor(
            (new Date().getTime() - lastResetTime) / 1000
          );
          if (timeDiff <= durationToAlert) {
            setTimer(durationToAlert - timeDiff);
          } else {
            setTimer(durationToAlert);
            await updateLastResetTime(userID);
          }
        }
      }
    );

    const q = query(collection(db, "progress"), where("uid", "==", userID));
    const progressUnsubscribe = onSnapshot(q, (querySnapshot) => {
      const agentsDict = {};
      for (let agent of agents) {
        agentsDict[agent.title] = agent;
      }
      const data = [];
      let maxPointsTemp = 0;

      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        const nodeData = { id: doc.id, ...docData };
        data.push(nodeData);
        if (nodeData.title !== "Life" && nodeData.points > maxPointsTemp) {
          maxPointsTemp = nodeData.points;
        }
        if (nodeData.parent === "Life") {
          agentsDict[docData.title] = {
            ...docData,
            title: docData.agent,
            task: docData.title,
          };
        }
      });
      setAgents(Object.values(agentsDict));
      setProgressData(buildTree(data));
      setMaxPoints(maxPointsTemp === 0 ? 1 : maxPointsTemp);
    });

    const checkAndUpdateToday = async () => {
      const todayData = await fetchTodayData(userID);
      const todayString = getTodayString();

      if (!todayData || todayData.date !== todayString) {
        await clearTodayData(userID);
      }
    };

    checkAndUpdateToday();

    return () => {
      timestampsUnsubscribe();
      progressUnsubscribe();
    };
  }, [db, auth]);

  useEffect(() => {
    let interval = null;
    if (!stopd) {
      interval = setInterval(() => {
        if (timer > 0) {
          // if (timer % 60 === 0 && !talking) {
          //   setAgents((preAgents) => {
          //     for (let agent of preAgents) {
          //       const otherAgents = [];
          //       preAgents.forEach((otherAgent) => {
          //         if (otherAgent.title !== agent.title) {
          //           otherAgents.push(otherAgent.title);
          //         }
          //       });
          //       if (!agent.lastResetTime) {
          //         setLastUpdated(agent.task);
          //       } else if (
          //         agent.lastResetTime.toDate().getTime() +
          //           agent.spacing * 60000 <
          //         new Date().getTime()
          //       ) {
          //         sendMessageToChatGPT(
          //           agent,
          //           otherAgents.join(", "),
          //           "",
          //           filterProgress(progressData, agent.task)
          //         );
          //         talking = true;
          //         setTimeout(() => {
          //           talking = false;
          //         }, 13000);
          //         break;
          //       }
          //     }
          //     return preAgents;
          //   });
          // }
          setTimer(timer - 1);
        } else {
          setShowAlert(true);
          updateLastResetTime(UID);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [db, UID, timer, stopd]);

  const resetTimer = () => {
    updateLastResetTime(UID); // Reset to 10 minutes
    setShowAlert(false);
  };

  const stopPlay = () => {
    if (stopd) {
      agents.forEach((agent) => {
        setLastUpdated(agent.task);
        updateLastResetTime(UID);
        setTimer(600);
      });
    }
    setStop((prev) => !prev);
  };

  // const handleDragStart = (e) => {
  //   e.preventDefault();
  //   dividerPositionRef.current = (e.clientX / window.innerWidth) * 100;
  //   window.addEventListener("mousemove", handleDragging);
  //   window.addEventListener("mouseup", handleDragEnd);
  // };

  // const handleDragging = (e) => {
  //   // Only update the position if the mouse has moved a significant amount (e.g., more than 5 pixels)
  //   if (
  //     !dividerPositionRef.current ||
  //     Math.abs(dividerPositionRef.current - e.clientX) > 5
  //   ) {
  //     const newWidth = (e.clientX / window.innerWidth) * 100;
  //     setLeftPanelWidth(newWidth);
  //     dividerPositionRef.current = e.clientX;
  //   }
  // };

  // const handleDragEnd = () => {
  //   window.removeEventListener("mousemove", handleDragging);
  //   window.removeEventListener("mouseup", handleDragEnd);
  // };

  const updateNodePoints = async (event, node, incrementValue) => {
    event.stopPropagation(); // Prevent event from bubbling up

    if (node.parent === "Life") {
      await setLastUpdated(node.title);
    }

    const updatePoints = async (currentNode) => {
      const docRef = doc(db, "progress", currentNode.id);
      await updateDoc(docRef, { points: increment(incrementValue) });

      if (currentNode.parent && currentNode.parent !== "Life") {
        const q = query(
          collection(db, "progress"),
          where("title", "==", currentNode.parent),
          where("uid", "==", auth.currentUser.uid)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          querySnapshot.forEach(async (docSnap) => {
            await updatePoints({ ...docSnap.data(), id: docSnap.id });
          });
        }
      }
    };

    await updatePoints(node);

    // After updating points, update the activity in the 'today' collection
    await updateActivity(node, incrementValue);

    resetTimer(); // Reset the timer on each point update
  };

  const handleDecrementOpen = (event, node) => {
    event.stopPropagation();
    setNodeToDecrement(node);
    setConfirmDialogOpen(true);
  };

  const handleDecrementConfirm = () => {
    if (nodeToDecrement) {
      updateNodePoints(new Event("click"), nodeToDecrement, -1);
    }
    setConfirmDialogOpen(false);
    setNodeToDecrement(null);
  };

  const handleDecrementCancel = () => {
    setConfirmDialogOpen(false);
    setNodeToDecrement(null);
  };

  const handleDeleteOpen = (event, node) => {
    event.stopPropagation();
    setNodeToDelete(node);
    setDeleteConfirmDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (nodeToDelete) {
      // Check if the node has children
      // const q = query(
      //   collection(db, "progress"),
      //   where("parent", "==", nodeToDelete.title),
      //   where("uid", "==", auth.currentUser.uid)
      // );
      // const querySnapshot = await getDocs(q);

      // if (!querySnapshot.empty) {
      //   alert("This node has child nodes and cannot be deleted.");
      // } else {
      const promiseArray = [];
      if (nodeToDelete.children) {
        for (let child of nodeToDelete.children) {
          promiseArray.push(deleteDoc(doc(db, "progress", child.id)));
        }
      }
      promiseArray.push(deleteDoc(doc(db, "progress", nodeToDelete.id)));
      await Promise.all(promiseArray);
      // }
    }
    setDeleteConfirmDialogOpen(false);
    setNodeToDelete(null);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmDialogOpen(false);
    setNodeToDelete(null);
  };

  const handleTitleChange = (event, node) => {
    setEditingNode({ ...node, title: event.target.value });
  };

  const handleTitleSubmit = async (node) => {
    if (node.title !== editingNode.title && editingNode.title !== "") {
      let q = query(
        collection(db, "progress"),
        where("title", "==", editingNode.title),
        where("uid", "==", auth.currentUser.uid)
      );
      let querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        alert(
          "There is another node with the same title. Please choose a different one."
        );
      } else {
        const batch = writeBatch(db);

        const docRef = doc(db, "progress", node.id);
        batch.update(docRef, { title: editingNode.title });

        // Fetch and update child nodes
        q = query(
          collection(db, "progress"),
          where("parent", "==", node.title),
          where("uid", "==", auth.currentUser.uid)
        );
        querySnapshot = await getDocs(q);
        querySnapshot.forEach((docSnap) => {
          batch.update(doc(db, "progress", docSnap.id), {
            parent: editingNode.title,
          });
        });

        await batch.commit();
      }
    }
    setEditingNode(null);
    setNewNodeId(null); // Update the new node ID
  };

  const addNewChild = async (event, parent) => {
    event.stopPropagation(); // Prevent event from bubbling up

    let newTitle = "New Title";
    let q = query(
      collection(db, "progress"),
      where("title", "==", newTitle),
      where("uid", "==", auth.currentUser.uid)
    );
    let querySnapshot = await getDocs(q);

    while (!querySnapshot.empty) {
      newTitle += "1";
      q = query(
        collection(db, "progress"),
        where("title", "==", newTitle),
        where("uid", "==", auth.currentUser.uid)
      );
      querySnapshot = await getDocs(q);
    }
    const newChildRef = await addDoc(collection(db, "progress"), {
      uid: auth.currentUser.uid,
      title: newTitle,
      points: 0,
      parent: parent.title,
    });

    setNewNodeId(newChildRef.id); // Update the new node ID
    setEditingNode({ id: newChildRef.id, title: "New Title" }); // Set the new node for editing

    setTimeout(() => {
      handleExpandedItemChange(event, newChildRef.id);
    }, 400);
  };

  const handleFocus = (event) => {
    // Selects the whole text in the input field
    event.target.select();
  };

  const handleKeyPress = (e, node) => {
    if (e.key === "Enter") {
      handleTitleSubmit(node);
    }
  };

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds);
  };

  const handleExpandClick = () => {
    setExpandedItems((oldExpanded) =>
      oldExpanded.length === 0 ? getAllItemsWithChildrenItemIds() : []
    );
  };

  const getAllItemsWithChildrenItemIds = () => {
    const itemIds = [];
    const registerItemId = (item) => {
      itemIds.push(item.id);
      if (item.children?.length) {
        item.children.forEach(registerItemId);
      }
    };

    progressData.children.forEach(registerItemId);

    return itemIds;
  };

  const handleExpandedItemChange = (event, nodeId) => {
    setExpandedItems((prevExpandedItems) =>
      prevExpandedItems.includes(nodeId)
        ? prevExpandedItems.filter((id) => id !== nodeId)
        : [...prevExpandedItems, nodeId]
    );
  };

  const renderTree = (node) => {
    if (!node || typeof node.title === "undefined" || node.title === null) {
      return null; // Skip rendering if node is null or title is not defined
    }

    const colorValue = (node.points / maxPoints) * 255;
    const nodeColor = `rgb(${255 - colorValue}, ${colorValue}, 0)`;

    return (
      <TreeItem
        key={node.id}
        nodeId={node.id}
        onClick={(event) => handleExpandedItemChange(event, node.id)}
        label={
          <div style={{ display: "flex", alignItems: "center" }}>
            {editingNode?.id === node.id || newNodeId === node.id ? (
              <TextField
                size="small"
                value={editingNode?.title || ""}
                onChange={(e) => handleTitleChange(e, node)}
                onBlur={() => handleTitleSubmit(node)}
                onKeyDown={(e) => handleKeyPress(e, node)}
                ref={inputRef}
                onFocus={handleFocus}
                autoFocus
              />
            ) : (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  setEditingNode(node);
                }}
              >
                {node.title}
              </span>
            )}
            {node.image && (
              <Box
                component="img"
                sx={{
                  height: 160, // You can set the height
                  width: 250, // and the width
                  maxHeight: { xs: 70, md: 160 },
                  maxWidth: { xs: 100, md: 250 },
                }}
                src={node.image}
                alt={node.title + " image"}
              />
            )}
            <span style={{ flexGrow: 1 }}></span>
            {node.title !== "Life" && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  size="small"
                  onClick={(e) => updateNodePoints(e, node, 1)}
                  style={{ color: "green" }}
                >
                  <CheckIcon />
                </IconButton>
                <span style={{ marginRight: "10px" }}>{node.points}</span>
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    borderRadius: "50%",
                    backgroundColor: nodeColor,
                  }}
                ></div>
                <IconButton
                  size="small"
                  onClick={(e) => handleDecrementOpen(e, node)}
                  style={{ color: "red" }}
                >
                  <CloseIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={(e) => addNewChild(e, node)}
                  style={{ color: "orange" }}
                >
                  <AddIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={(e) => handleDeleteOpen(e, node)}
                  style={{ color: "gray" }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </div>
            )}
          </div>
        }
      >
        {node.children &&
          node.children.map((childNode) => renderTree(childNode))}
      </TreeItem>
    );
  };

  const filterProgress = (progressData, title) => {
    for (let node of progressData.children) {
      if (node.title === title) {
        return node;
      }
      filterProgress(progressData, title);
    }
  };

  return (
    // <div
    //   style={{
    //     display: "flex",
    //     height: `calc(100vh - ${navbarHeight})`, // Adjusted height
    //     width: "100%",
    //     overflow: "hidden", // Remove outer scroll-bar
    //     overflow: "hidden", // Remove outer scroll-bar
    //     flexDirection: horizontal ? "row" : "column",
    //   }}
    // >
    //   <div
    //     style={{
    //       width: horizontal ? "50%" : "100%", // Set width to 50%
    //       height: horizontal
    //         ? `calc(100vh - ${navbarHeight})`
    //         : `calc(50vh - ${navbarHeight})`, // Adjusted height
    //       overflow: "auto",
    //       backgroundColor: `rgba(255,0,0,${
    //         (durationToAlert - timer) / durationToAlert
    //       })`,
    //     }}
    //   >
    <div>
      <DeleteDialog
        open={deleteConfirmDialogOpen}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />
      <DecrementDialog
        open={confirmDialogOpen}
        onClose={handleDecrementCancel}
        onConfirm={handleDecrementConfirm}
      />

      {showAlert && (
        <Alert
          severity="error"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            fontSize: "34px",
            zIndex: 1000,
          }}
        >
          10 Minutes Passed...
          <Button onClick={resetTimer}>Reset Timer</Button>
        </Alert>
      )}
      <div style={{ display: "flex", padding: "10px" }}>
        <Button onClick={handleExpandClick} sx={{ m: 0 }}>
          {expandedItems.length === 0 ? "Expand all" : "Collapse all"}
        </Button>
        <span style={{ flexGrow: 1 }}></span>
        <span style={{ fontSize: "25px" }}>
          {Math.floor(timer / 60)}:{String(timer % 60).padStart(2, "0")}
        </span>
        <IconButton
          onClick={stopPlay}
          style={{
            color: stopd ? "red" : "white",
            marginLeft: "10px",
            marginTop: "-5.5px",
          }}
        >
          {stopd ? (
            <PlayCircleOutlineIcon fontSize="large" />
          ) : (
            <StopCircleIcon fontSize="large" />
          )}
        </IconButton>
        <span style={{ flexGrow: 1.5 }}></span>
        <IconButton
          size="small"
          onClick={(e) => addNewChild(e, { title: "Life" })}
          style={{ color: "orange" }}
        >
          <AddIcon />
        </IconButton>
      </div>
      <TreeView
        // defaultExpanded={
        //   progressData &&
        //   progressData.children &&
        //   progressData.children.map((child) => child.id)
        // }
        expanded={expandedItems}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {progressData &&
          progressData.children &&
          progressData.children.map((child) => renderTree(child))}
      </TreeView>
      {/* <div
        style={{
          width: "5px",
          cursor: "ew-resize",
          backgroundColor: "#ccc",
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onMouseDown={handleDragStart}
      >
        <div
          style={{ width: "1px", height: "50%", backgroundColor: "black" }}
        />
      </div>

      <div
        style={{
          width: horizontal ? "50%" : "100%",
          height: horizontal ? "100%" : "50%",
          borderLeft: horizontal ? "1px solid #333" : "0px",
          borderTop: horizontal ? "0px" : "1px solid #333",
        }}
      >
        <ChatBox
          uid={auth.currentUser.uid}
          progressData={progressData}
          filterProgress={filterProgress}
          agents={agents}
        />
      </div> */}
    </div>
  );
};

export default Dashboard;
